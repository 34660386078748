<template>
  <v-row dense>
    <v-col cols="12">
      <ProductsQueryFilter
        :codes="codes"
        @filter="filter"
        @clearFilter="clearFilter"
        @printSelected="setSelectedPrint"
      />
    </v-col>
    <v-col
      class="overflow-auto"
      cols="12"
      :md="isMini ? '1' : '3'"
    >
      <ProductsQueryPriceCalculator
        :selected-print="selectedPrint"
        :is-loading="isLoadingCalculator"
        @addRow="addRow"
        @switchMini="(value) => (isMini = value)"
      />
    </v-col>
    <v-col
      cols="12"
      :md="isMini ? '11' : '9'"
    >
      <v-row dense>
        <v-col
          v-for="(product, idx) in products"
          :key="idx"
          cols="12"
          :md="isMini ? '3' : '4'"
        >
          <ProductsQueryCard
            :product="product"
            :rows="rows"
            :selected-print="selectedPrint"
            :fixed-value="fixedValue"
            :settings="settings"
            @cardClicked="cardClicked"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    ProductsQueryCard: () =>
      import('@/components/products-query/ProductsQueryCard'),
    ProductsQueryPriceCalculator: () =>
      import('@/components/products-query/ProductsQueryPriceCalculator'),
    ProductsQueryFilter: () =>
      import('@/components/products-query/ProductsQueryFilter'),
  },
  data: () => ({
    codes: null,
    products: [],
    rows: [],
    isMini: false,
    selectedPrint: null,
    fixedValue: 0,
    settings: null,
    isLoadingCalculator: false,
  }),
  methods: {
    getFilter(objFilter) {
      this.$api
        .post('products/filter/budget', objFilter)
        .then((res) => {
          this.products = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },

    clearFilter() {
      this.products = []

      this.vendorPriceTables.items = []
    },
    addRow(rows) {
      this.rows = rows
      this.$emit('addRow', rows)
    },
    cardClicked(product, selectedTable, rows, selectedPrint) {
      this.$emit('cardClicked', product, selectedTable, rows, selectedPrint)
    },
    getPrintById(id) {
      this.isLoadingCalculator = true
      this.$api
        .get(`product_prints/${id}`)
        .then((res) => {
          this.selectedPrint = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoadingCalculator = false
        })
    },

    setSelectedPrint(id) {
      if (id !== undefined) {
        this.getPrintById(id)
      } else {
        this.selectedPrint = null
      }
    },
    getSettings() {
      this.$api
        .get('settings')
        .then((res) => {
          this.settings = res.data.data
          this.fixedValue = parseFloat(res.data.data.fix_value)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    filter(objFilter) {
      this.getFilter(objFilter)
    },
  },
}
</script>
